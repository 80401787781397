import React, { useEffect } from 'react';
import { useWindowSize } from '../hooks/useWindowSize';
import { skillsAnimation } from '../utils/skillsAnimation';
import { navBarAnimation } from '../utils/navBarAnimation';

type Props = {
    sectionRef: React.MutableRefObject<HTMLElement[]>;
    about: string;
    skills: {
        languages: string[];
        frontend: string[];
        backend: string[];
        others: string[];
    };
};

const About = ({ sectionRef, about, skills }: Props) => {
    const size = useWindowSize();

    useEffect(() => {
        if (typeof window !== `undefined`) {
            const skills = document.querySelectorAll<HTMLElement>('.skills')[0];
            if (window.pageYOffset > window.innerHeight / 2) {
                if (!skills.classList.contains('opaque')) {
                    skills.classList.replace('transparent', 'opaque');
                }
            }
            window.onscroll = function () {
                const scroll = document.documentElement.scrollTop;

                if (scroll > 400) {
                    if (!skills.classList.contains('opaque')) {
                        skills.classList.replace('transparent', 'opaque');
                        skillsAnimation();
                    }
                }

                navBarAnimation(scroll, window);
            };
        }
    }, []);

    useEffect(() => {
        const skills = document.querySelectorAll<HTMLElement>('.skills')[0];
        if (skills.classList.contains('opaque')) {
            skillsAnimation();
        }
    }, [size.width]);

    return (
        <section
            id="about"
            ref={(element: HTMLElement) => (sectionRef.current[1] = element)}
        >
            <h2 id="about-me">{about}</h2>
            <div className="skills transparent">
                {skills &&
                    skills.languages.map(skill => {
                        return (
                            <div
                                key={skill}
                                className="skill-container transparent"
                            >
                                <div
                                    className={`skill-icon ${skill.toLowerCase()}`}
                                ></div>
                                <div className="skill">{skill}</div>
                            </div>
                        );
                    })}
                {skills &&
                    skills.frontend.map(skill => {
                        return (
                            <div
                                key={skill}
                                className="skill-container transparent"
                            >
                                <div
                                    className={`skill-icon ${skill.toLowerCase()}`}
                                ></div>
                                <div className="skill">{skill}</div>
                            </div>
                        );
                    })}
                {skills &&
                    skills.backend.map(skill => {
                        return (
                            <div
                                key={skill}
                                className="skill-container transparent"
                            >
                                <div
                                    className={`skill-icon ${skill.toLowerCase()}`}
                                ></div>
                                <div className="skill">{skill}</div>
                            </div>
                        );
                    })}
                {skills &&
                    skills.others.map(skill => {
                        return (
                            <div
                                key={skill}
                                className={`skill-container transparent`}
                            >
                                <div
                                    className={`skill-icon ${skill.toLowerCase()}`}
                                ></div>
                                <div className="skill">{skill}</div>
                            </div>
                        );
                    })}
            </div>
        </section>
    );
};

export default About;
