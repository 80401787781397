import { removeClassNameByIndex } from './domUtils';

const getRandomNumber = (
    min: number,
    max: number,
    notMin: number,
    notMax: number,
) => {
    for (;;) {
        const random = Math.random() * (max - min) + min;
        if (random < notMin || random > notMax) {
            return random;
        }
    }
};

export const skillsAnimation = () => {
    const skillContainers = document.querySelectorAll<HTMLElement>(
        '.skill-container',
    );

    if (window.innerWidth > 800) {
        const leftOffset = -50;
        let prevTop = 0;
        let prevLeft = 0 + leftOffset;
        let prevSkillContainerHeight = 0;

        const aboutMe = document.querySelectorAll<HTMLElement>('#about-me')[0];
        const about = document.querySelectorAll<HTMLElement>('#about')[0];
        const slice =
            (about.offsetWidth + Math.abs(leftOffset) * 6) /
            (skillContainers.length / 2 + 3);

        Array.from(skillContainers).forEach((skill: HTMLElement, idx) => {
            removeClassNameByIndex(skill, 1);
            skill.classList.add('opaque');
            skill.style.transitionDelay = `${(idx + 1) * 0.1}s`;
            if (idx < skillContainers.length / 2) {
                const skillTop = getRandomNumber(
                    0,
                    (about.offsetHeight - aboutMe.offsetHeight) / 2 - 35,
                    prevTop - prevSkillContainerHeight,
                    prevTop + prevSkillContainerHeight,
                );
                const skillLeft = prevLeft;

                skill.style.top = `${skillTop}px`;
                skill.style.left = `${skillLeft}px`;

                prevTop = skillTop;
                prevLeft = skillLeft + slice;

                if (idx + 1 >= skillContainers.length / 2) {
                    prevLeft = prevLeft - Math.abs(leftOffset) - slice;
                }
            } else {
                const skillTop = getRandomNumber(
                    (about.offsetHeight + aboutMe.offsetHeight) / 2,
                    about.offsetHeight - 35,
                    prevTop - prevSkillContainerHeight,
                    prevTop + prevSkillContainerHeight,
                );
                const skillLeft = prevLeft;

                skill.style.top = `${skillTop}px`;
                skill.style.left = `${skillLeft}px`;

                prevTop = skillTop;
                prevLeft = skillLeft - slice;
            }
            prevSkillContainerHeight = skill.offsetHeight;
        });
    } else if (window.innerWidth <= 800) {
        Array.from(skillContainers).forEach((skill: HTMLElement) => {
            skill.style.cssText = '';
            skill.classList.replace('transparent', 'opaque');
        });
    }
};
