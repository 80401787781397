import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';

type Project = {
    name: string;
    summary: string;
    description: string;
    thumbnail?: 'youtube' | 'image';
    github: string;
    website: string;
    skills: string[];
};

type Props = {
    sectionRef: React.MutableRefObject<HTMLElement[]>;
    projects: Project[];
};

const Project = ({ sectionRef, projects }: Props) => {
    const images = useStaticQuery(graphql`
        {
            pokedex: file(relativePath: { eq: "thumbnails/pokedex.webp" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            gameoflife: file(
                relativePath: { eq: "thumbnails/game-of-life.webp" }
            ) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            watermyplants: file(
                relativePath: { eq: "thumbnails/water-my-plants.webp" }
            ) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            spotifysongsuggester: file(
                relativePath: { eq: "thumbnails/spotify-song-suggester.webp" }
            ) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            ezinvoice: file(relativePath: { eq: "thumbnails/ezinvoice.webp" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    const handleClick = (idx: number) => {
        const currentProjectDetail = document.getElementsByClassName(
            'project-detail',
        )[idx];

        currentProjectDetail.classList.toggle('is-flipped');
    };

    return (
        <section
            id="projects"
            ref={(element: HTMLElement) => (sectionRef.current[2] = element)}
        >
            {projects &&
                projects.map((project: Project, idx) => {
                    return (
                        <div
                            key={project.name}
                            className={`project ${idx % 2 ? 'odd' : 'even'}`}
                        >
                            {project.thumbnail === 'image' ? (
                                <Img
                                    fluid={
                                        images[
                                            project.name
                                                .replace(/\s+/g, '')
                                                .toLowerCase()
                                        ]?.childImageSharp.fluid
                                    }
                                    objectFit="contain"
                                    className="project-thumbnail"
                                    alt={`${project.name} thumbnail`}
                                ></Img>
                            ) : (
                                <iframe
                                    title={`${project.name}`}
                                    className="project-thumbnail"
                                    src="https://www.youtube.com/embed/3xgOLyWJb3w"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            )}
                            <div className="project-detail">
                                <div className="project-detail-front">
                                    <h4>{project.name}</h4>
                                    <h5>{project.summary}</h5>
                                    <div className="project-skills">
                                        {project.skills &&
                                            project.skills.map(skill => (
                                                <div
                                                    key={skill}
                                                    className="project-skill-container"
                                                >
                                                    <div
                                                        className={`skill-icon ${skill.toLowerCase()}`}
                                                    ></div>
                                                    <div className="skill">
                                                        {skill}
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                    <div className="project-links-container">
                                        <a
                                            href={project.github}
                                            className="project-link project-github"
                                        ></a>
                                        <a
                                            href={project.website}
                                            className="project-link project-website"
                                        ></a>
                                        <div
                                            className="project-link project-info"
                                            onClick={() => {
                                                handleClick(idx);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="project-detail-back">
                                    <p className="project-description">
                                        {project.description}
                                    </p>
                                    <div className="project-links-container">
                                        <div
                                            className="project-link project-flip"
                                            onClick={() => {
                                                handleClick(idx);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
        </section>
    );
};

export default Project;
