import React, { useState } from 'react';
import emailjs from 'emailjs-com';

type Props = {
    sectionRef: React.MutableRefObject<HTMLElement[]>;
};

const Contact = ({ sectionRef }: Props) => {
    const [message, setMessage] = useState<string>('');

    const sendEmail = (e: { preventDefault: () => void; target: any }) => {
        e.preventDefault();

        emailjs
            .sendForm(
                `${process.env.GATSBY_EMAILJS_SERVICE_ID}`,
                `${process.env.GATSBY_EMAILJS_TEMPLATE_ID}`,
                e.target,
                `${process.env.GATSBY_EMAILJS_USER_ID}`,
            )
            .then(
                result => {
                    console.log(result.text);
                    setMessage('Awesome! I will get back to you ASAP😎');
                },
                error => {
                    console.log(error.text);
                    setMessage('😭Something went wrong');
                },
            );
    };

    return (
        <section
            id="contact"
            className="contact"
            ref={(element: HTMLElement) => (sectionRef.current[3] = element)}
        >
            <h3>Contact</h3>
            <h4>Have a question or want to work together?</h4>
            <form className="contact-form" onSubmit={sendEmail}>
                <div className="form-input">
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        name="user_name"
                        id="name"
                        placeholder="Name"
                        required
                    />
                </div>
                <div className="form-input">
                    <label htmlFor="email">Email: </label>
                    <input
                        type="email"
                        name="user_email"
                        id="email"
                        placeholder="Email"
                        required
                    />
                </div>
                <div className="form-input">
                    <label htmlFor="message">Message: </label>
                    <textarea
                        name="message"
                        id="message"
                        placeholder="Message"
                    />
                </div>

                <button type="submit">Send</button>
            </form>
            {message && <h4>{message}</h4>}
        </section>
    );
};

export default Contact;
