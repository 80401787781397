import { removeClassNameByIndex } from './domUtils';

export function navBarAnimation(
    scroll: number,
    window: Window & typeof globalThis,
) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const navBar: HTMLElement = document.querySelector<HTMLElement>('.nav')!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const about: HTMLElement = document.getElementById('nav-about')!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const projects: HTMLElement = document.getElementById('nav-projects')!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const contact: HTMLElement = document.getElementById('nav-contact')!;

    if (window.innerWidth >= 800) {
        if (scroll >= 400 || window.pageYOffset > window.innerHeight / 2) {
            removeClassNameByIndex(navBar, 1);
            navBar.classList.add('opaque');
            if (scroll >= 400 && scroll < 1000) {
                removeClassNameByIndex(about, 1);
                removeClassNameByIndex(projects, 1);
                removeClassNameByIndex(contact, 1);
                about.classList.add('selected-nav-link');
                projects.classList.add('not-selected-nav-link');
                contact.classList.add('not-selected-nav-link');
            } else if (scroll >= 1000 && scroll < 3800) {
                removeClassNameByIndex(about, 1);
                removeClassNameByIndex(projects, 1);
                removeClassNameByIndex(contact, 1);
                about.classList.add('not-selected-nav-link');
                projects.classList.add('selected-nav-link');
                contact.classList.add('not-selected-nav-link');
            } else if (scroll >= 3800) {
                removeClassNameByIndex(about, 1);
                removeClassNameByIndex(projects, 1);
                removeClassNameByIndex(contact, 1);
                about.classList.add('not-selected-nav-link');
                projects.classList.add('not-selected-nav-link');
                contact.classList.toggle('selected-nav-link');
            }
        } else {
            navBar.classList.remove('opaque');
            navBar.classList.add('transparent');
            removeClassNameByIndex(about, 1);
            removeClassNameByIndex(projects, 1);
            removeClassNameByIndex(contact, 1);
            about.classList.add('not-selected-nav-link');
            projects.classList.add('not-selected-nav-link');
            contact.classList.add('not-selected-nav-link');
        }
    } else {
        removeClassNameByIndex(navBar, 1);
        navBar.classList.add('opaque');
        removeClassNameByIndex(about, 1);
        removeClassNameByIndex(projects, 1);
        removeClassNameByIndex(contact, 1);
        about.classList.add('mobile-nav-link');
        projects.classList.add('mobile-nav-link');
        contact.classList.add('mobile-nav-link');
        if (scroll > 400 && scroll < 1000) {
            removeClassNameByIndex(about, 1);
            removeClassNameByIndex(projects, 1);
            removeClassNameByIndex(contact, 1);
            about.classList.add('mobile-selected-nav-link');
            projects.classList.add('mobile-not-selected-nav-link');
            contact.classList.add('mobile-not-selected-nav-link');
        } else if (scroll >= 1000 && scroll < 3100) {
            removeClassNameByIndex(about, 1);
            removeClassNameByIndex(projects, 1);
            removeClassNameByIndex(contact, 1);
            about.classList.add('mobile-not-selected-nav-link');
            projects.classList.add('mobile-selected-nav-link');
            contact.classList.add('mobile-not-selected-nav-link');
        } else if (scroll > 3100) {
            removeClassNameByIndex(about, 1);
            removeClassNameByIndex(projects, 1);
            removeClassNameByIndex(contact, 1);
            about.classList.add('mobile-not-selected-nav-link');
            projects.classList.add('mobile-not-selected-nav-link');
            contact.classList.toggle('mobile-selected-nav-link');
        } else {
            removeClassNameByIndex(about, 1);
            removeClassNameByIndex(projects, 1);
            removeClassNameByIndex(contact, 1);
            about.classList.add('mobile-not-selected-nav-link');
            projects.classList.add('mobile-not-selected-nav-link');
            contact.classList.toggle('mobile-not-selected-nav-link');
        }
    }
}
