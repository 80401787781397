import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Home from '../components/home';
import About from '../components/about';
import Project from '../components/project';
import Contact from '../components/contact';
import '../styles/index.scss';

type Props = {
    data: {
        allDataJson: {
            nodes: [
                {
                    about: string;
                    projects: {
                        name: string;
                        summary: string;
                        description: string;
                        thumbnail?: 'youtube';
                        github: string;
                        website: string;
                        skills: string[];
                    }[];
                    contact: {
                        email: string;
                        github: string;
                        linkedIn: string;
                    };
                    skills: {
                        languages: string[];
                        frontend: string[];
                        backend: string[];
                        others: string[];
                    };
                },
            ];
        };
    };
};

const IndexPage = ({ data }: Props) => {
    const about = data.allDataJson.nodes[0].about;
    const projects = data.allDataJson.nodes[0].projects;
    const skills = data.allDataJson.nodes[0].skills;

    const sectionRef = useRef<Array<HTMLElement>>([]);

    return (
        <Layout sectionRef={sectionRef}>
            <SEO title="Jiayi Ren" />
            <Home sectionRef={sectionRef} />
            <About sectionRef={sectionRef} about={about} skills={skills} />
            <Project sectionRef={sectionRef} projects={projects} />
            <Contact sectionRef={sectionRef} />
        </Layout>
    );
};

export default IndexPage;

export const query = graphql`
    {
        allDataJson {
            nodes {
                about
                projects {
                    name
                    summary
                    description
                    thumbnail
                    github
                    website
                    skills
                }
                contact {
                    email
                    github
                    linkedIn
                }
                skills {
                    languages
                    frontend
                    backend
                    others
                }
            }
        }
    }
`;
