import React from 'react';

type Props = {
    sectionRef: React.MutableRefObject<HTMLElement[]>;
};

const Home = ({ sectionRef }: Props) => {
    return (
        <section
            id="home"
            ref={(element: HTMLElement) => (sectionRef.current[0] = element)}
        >
            <div className="name-container">
                <h4>Hi, I&apos;m</h4>
                <h1>Jiayi Ren</h1>
            </div>
        </section>
    );
};

export default Home;
